* {
  box-sizing: border-box;
}

body {
  font-display: swap;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 16px;
  line-height: 1.5;
}

a, a:visited {
  color: #2d2d2d;
  font-weight: bold;
}

a:hover, a:visited:hover {
  color: #1e90ff;
}

@media (prefers-color-scheme: dark) {
  body {
    color: #fff;
    background: #2d2d2d;
  }

  body a, body a:visited {
    color: #fff;
  }
}

body {
  max-width: 66ch;
  margin: 0 auto;
  padding: 1em;
  overflow-x: hidden;
}

footer {
  margin: 4rem 0;
}
/*# sourceMappingURL=index.875dbc5e.css.map */
