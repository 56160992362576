// Typography
//
// Headings, body text, lists, and other misc typographic elements.

body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  font-size: $root-font-size;
  line-height: $root-line-height;
  font-display: swap;
}

a,
a:visited {
  color: $body-color;
  font-weight: bold;

  &:hover {
    color: $brand-color;
  }
}

// Dark Mode
@media (prefers-color-scheme: dark) {
  body {
    background: $body-color;
    color: white;

    a,
    a:visited {
      color: white;
    }
  }
}
