body {
  margin: 0 auto;
  max-width: 66ch;
  overflow-x: hidden;
  padding: 1em;
}

footer {
  margin: 4rem 0;
}
